import type { AxiosError } from 'axios';
import type { Effect } from 'effector';

import { TIME_24_HOURS } from '@core/constants';
import type { JSENDAPIResponse, RequestConfig } from '@core/http';
import { request } from '@core/http';

import type { IVehicle } from '../vehicle/internal';

import type {
	DealerSiteDeliveryTransport,
	DeliveryTransport,
	DeliveryTransportOptions,
	GetTransportProvidersResponse,
} from './model';
import { domain } from './utils';

/**
 * Saves and returns the chosen transport option
 */
export const saveDeliveryFx: Effect<
	{ data: Partial<DeliveryTransport>; enquiryId: IVehicle['id'] },
	DeliveryTransport
> = domain.effect({
	handler: ({ data, enquiryId }) =>
		request({
			data,
			method: 'post',
			url: `/transaction/${enquiryId}/updateEnquiryTransport`,
		} as RequestConfig),
});

/**
 * Cancels booking and returns cancelledAt time
 */
export const cancelDeliveryFx: Effect<{ enquiryId: IVehicle['id'] }, { cancelledAt: string }> = domain.effect({
	handler: ({ enquiryId }) =>
		request({
			method: 'post',
			url: `/transaction/${enquiryId}/cancelEnquiryTransport`,
		} as RequestConfig),
});

/**
 * Fetch delivery transport
 */
export const getDeliveryTransportFx: Effect<{ enquiryId: IVehicle['id'] }, DeliveryTransport> = domain.effect({
	handler: ({ enquiryId }) =>
		request({
			headers: {
				'cache-control': 'no-cache',
			},
			method: 'get',
			url: `/transaction/${enquiryId}/transport`,
		} as RequestConfig),
});

/**
 * Fetch delivery transport v2
 */
export const getDealerSiteDeliveryTransportFx: Effect<{ enquiryId: IVehicle['id'] }, DealerSiteDeliveryTransport> =
	domain.effect({
		handler: ({ enquiryId }) =>
			request({
				headers: {
					'cache-control': 'no-cache',
				},
				method: 'get',
				url: `/transaction/v2/${enquiryId}/transport`,
			} as RequestConfig),
	});

/**
 * List of transport providers
 */
export const getTransportProvidersFx: Effect<void, JSENDAPIResponse<GetTransportProvidersResponse>> = domain.effect({
	handler: () =>
		request({
			cacheTTL: TIME_24_HOURS,
			method: 'get',
			url: `/transaction/transportProviders`,
		} as RequestConfig),
});

/**
 * Fetch delivery transport options
 */
export const getDeliveryTransportOptionsFx: Effect<
	{ enquiryId: IVehicle['id'] },
	DeliveryTransportOptions,
	AxiosError<{ message: string }>
> = domain.effect({
	handler: ({ enquiryId }) =>
		request({
			method: 'get',
			url: `/transaction/${enquiryId}/transport-options`,
		} as RequestConfig),
});

/**
 * Fetch delivery transport options
 */
export const getDealerSiteTransportOptionsFx: Effect<
	{ enquiryId: IVehicle['id'] },
	any,
	AxiosError<{ message: string }>
> = domain.effect({
	handler: ({ enquiryId }) =>
		request({
			method: 'get',
			url: `/transaction/v2/${enquiryId}/transport-options`,
		} as RequestConfig),
});

import { sample } from 'effector';

import { SNOWPLOW_EVENT_TYPE, SnowplowEventCategory } from './constants';
import * as effects from './effects';
import * as events from './events';
import { sendPhotoActionEventCategory, sendPhotoActionEventName } from './helpers';

/**
 * Snowplow - Photo action event
 */
sample({
	clock: events.sendPhotoActionEvent,
	fn: ({ action, photo }) => ({
		category: sendPhotoActionEventCategory(photo),
		label: `${photo.name} - ${photo.modifiedUrl ? 'modified' : 'default'}`,
		name: sendPhotoActionEventName(action, photo),
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Restore photo from archive event
 */
sample({
	clock: events.sendRestorePhotoFromArchiveEvent,
	fn: ({ kind }) => ({
		category: SnowplowEventCategory.IMAGES,
		label: kind,
		name: 'ad_image_restore_from_archive',
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Gallery opened event
 */
sample({
	clock: events.sendGalleryOpenEvent,
	fn: () => ({
		category: SnowplowEventCategory.IMAGES,
		name: 'ad_gallery_opened',
		...SNOWPLOW_EVENT_TYPE.POPUP_APPEAR,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Gallery navigation event
 */
sample({
	clock: events.sendGalleryClickEvent,
	fn: ({ direction }) => ({
		category: SnowplowEventCategory.IMAGES,
		name: `ad_gallery_${direction}_click`,
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - QA validation warning event
 */
sample({
	clock: events.sendQAValidationWarningEvent,
	fn: ({ numberOfErrors }) => ({
		category: SnowplowEventCategory.STATUS_CHANGE,
		label: numberOfErrors,
		name: 'ad_qa_validation_warning',
		...SNOWPLOW_EVENT_TYPE.POPUP_APPEAR,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - QA validation overridden event
 */
sample({
	clock: events.sendQAValidationOverriddenEvent,
	fn: () => ({
		category: SnowplowEventCategory.STATUS_CHANGE,
		label: 'QA Validation',
		name: 'ad_qa_validation_overriden',
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Request resupply modal open event
 */
sample({
	clock: events.sendRequestResupplyModalOpenEvent,
	fn: () => ({
		category: SnowplowEventCategory.IMAGES,
		name: 'ad_request_resupply_modal_open',
		...SNOWPLOW_EVENT_TYPE.POPUP_APPEAR,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Resupply requested event
 */
sample({
	clock: events.sendResupplyRequestedEvent,
	fn: () => ({
		category: SnowplowEventCategory.IMAGES,
		name: 'ad_requested_resupply',
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Image for resupply selected event
 */
sample({
	clock: events.sendImageResupplySelectedEvent,
	fn: ({ kind }) => ({
		category: SnowplowEventCategory.IMAGES,
		label: kind.slug,
		name: 'ad_request_image_resupply_selected',
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Resupply docs request event
 */
sample({
	clock: events.sendResupplyDocsRequestEvent,
	fn: ({ name }) => ({
		category: SnowplowEventCategory.DOCUMENTS,
		name: `ad_${name
			.split(' ')
			.map((c) => c.toLowerCase())
			.join('_')}_selected`,
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Manual payment - click
 */
sample({
	clock: events.manualPaymentEventClick,
	fn: ({ button_label, label, name }) => ({
		button_label,
		category: SnowplowEventCategory.MANUAL_PAYMENT,
		label,
		name,
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Manual payment - cta clicked
 */
sample({
	clock: events.manualPaymentEventCtaClicked,
	fn: ({ button_label, label, name }) => ({
		button_label,
		category: SnowplowEventCategory.MANUAL_PAYMENT,
		label,
		name,
		...SNOWPLOW_EVENT_TYPE.CTA_CLICKED,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - KYC - Download PDF Clicked
 */
sample({
	clock: events.downloadKycPdfEventClicked,
	fn: () => ({
		category: SnowplowEventCategory.KYC,
		label: 'download_as_pdf_button',
		name: 'download_as_pdf_button_clicked',
		...SNOWPLOW_EVENT_TYPE.CTA_CLICKED,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - KYC - Download PDF - Modal Confirmation Clicked
 */
sample({
	clock: events.downloadKycPdfModalConfirmationEventClicked,
	fn: () => ({
		category: SnowplowEventCategory.KYC,
		label: 'download_as_pdf_download_modal',
		name: 'download_as_pdf_download_modal_button_clicked',
		...SNOWPLOW_EVENT_TYPE.CTA_CLICKED,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - KYC - Download PDF - Modal Cancel Clicked
 */
sample({
	clock: events.downloadKycPdfModalCancelEventClicked,
	fn: () => ({
		category: SnowplowEventCategory.KYC,
		label: 'download_as_pdf_cancel_modal_button',
		name: 'download_as_pdf_cancel_modal_button_clicked',
		...SNOWPLOW_EVENT_TYPE.CTA_CLICKED,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Request Docs Button Clicked
 */
sample({
	clock: events.requestDocsButtonClicked,
	fn: () => ({
		category: SnowplowEventCategory.DOCUMENTS,
		label: 'request_docs_button',
		name: 'request_docs_button_clicked',
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Mark seller funds receieved button clicked
 */
sample({
	clock: events.markSellerFundsReceivedButtonClicked,
	fn: () => ({
		category: SnowplowEventCategory.NEGATIVE_EQUITY,
		label: 'mark_seller_funds_received_button_clicked',
		name: 'Mark seller funds received button clicked',
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Unmark seller funds receieved button clicked
 */
sample({
	clock: events.unmarkSellerFundsReceivedButtonClicked,
	fn: () => ({
		category: SnowplowEventCategory.NEGATIVE_EQUITY,
		label: 'unmark_seller_funds_received_button_clicked',
		name: 'Unmark seller funds received button clicked',
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Mark seller funds received modal open
 */
sample({
	clock: events.markSellerFundsReceivedModalOpen,
	fn: () => ({
		category: SnowplowEventCategory.NEGATIVE_EQUITY,
		label: 'mark_seller_funds_received_modal_open',
		name: 'Mark seller funds received modal opened',
		...SNOWPLOW_EVENT_TYPE.POPUP_APPEAR,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Mark seller funds received modal close
 */
sample({
	clock: events.markSellerFundsReceivedModalClose,
	fn: () => ({
		category: SnowplowEventCategory.NEGATIVE_EQUITY,
		label: 'mark_seller_funds_received_modal_close',
		name: 'Mark seller funds received modal closed',
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Mark seller funds received confirm button clicked
 */
sample({
	clock: events.markSellerFundsReceivedConfirmButtonClicked,
	fn: () => ({
		category: SnowplowEventCategory.NEGATIVE_EQUITY,
		label: 'mark_seller_funds_received_confirm_button_clicked',
		name: 'Mark seller funds received confirm button clicked',
		...SNOWPLOW_EVENT_TYPE.CTA_CLICKED,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Mark seller funds received cancel button clicked
 */
sample({
	clock: events.markSellerFundsReceivedCancelButtonClicked,
	fn: () => ({
		category: SnowplowEventCategory.NEGATIVE_EQUITY,
		label: 'mark_seller_funds_received_cancel_button_clicked',
		name: 'Mark seller funds received cancel button clicked',
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Unmark seller funds received modal open
 */
sample({
	clock: events.unmarkSellerFundsReceivedModalOpen,
	fn: () => ({
		category: SnowplowEventCategory.NEGATIVE_EQUITY,
		label: 'unmark_seller_funds_received_modal_open',
		name: 'Unmark seller funds received modal opened',
		...SNOWPLOW_EVENT_TYPE.POPUP_APPEAR,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Unmark seller funds received modal close
 */
sample({
	clock: events.unmarkSellerFundsReceivedModalClose,
	fn: () => ({
		category: SnowplowEventCategory.NEGATIVE_EQUITY,
		label: 'unmark_seller_funds_received_modal_close',
		name: 'Unmark seller funds received modal closed',
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Unmark seller funds received confirm button clicked
 */
sample({
	clock: events.unmarkSellerFundsReceivedConfirmButtonClicked,
	fn: () => ({
		category: SnowplowEventCategory.NEGATIVE_EQUITY,
		label: 'unmark_seller_funds_received_confirm_button_clicked',
		name: 'Unmark seller funds received confirm button clicked',
		...SNOWPLOW_EVENT_TYPE.CTA_CLICKED,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Unmark seller funds received cancel button clicked
 */
sample({
	clock: events.unmarkSellerFundsReceivedCancelButtonClicked,
	fn: () => ({
		category: SnowplowEventCategory.NEGATIVE_EQUITY,
		label: 'unmark_seller_funds_received_cancel_button_clicked',
		name: 'Unmark seller funds received cancel button clicked',
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Update finance settlement amount modal open
 */
sample({
	clock: events.updateFinanceSettlementAmountModalOpen,
	fn: () => ({
		category: SnowplowEventCategory.NEGATIVE_EQUITY,
		label: 'update_finance_settlement_amount_modal_open',
		name: 'Update finance amount modal opened',
		...SNOWPLOW_EVENT_TYPE.POPUP_APPEAR,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Update finance settlement amount modal closed
 */
sample({
	clock: events.updateFinanceSettlementAmountModalClosed,
	fn: () => ({
		category: SnowplowEventCategory.NEGATIVE_EQUITY,
		label: 'update_finance_settlement_amount_modal_closed',
		name: 'Update finance amount modal closed',
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Update finance settlement amount modal confirm clicked
 */
sample({
	clock: events.updateFinanceSettlementAmountModalConfirmClicked,
	fn: () => ({
		category: SnowplowEventCategory.NEGATIVE_EQUITY,
		label: 'update_finance_settlement_amount_modal_confirm_clicked',
		name: 'Update finance amount modal confirm clicked',
		...SNOWPLOW_EVENT_TYPE.CTA_CLICKED,
	}),
	target: effects.snowplowEventFx,
});

/**
 * Snowplow - Unmark seller funds received cancel button clicked
 */
sample({
	clock: events.unmarkSellerFundsReceivedCancelButtonClicked,
	fn: () => ({
		category: SnowplowEventCategory.NEGATIVE_EQUITY,
		label: 'unmark_seller_funds_received_cancel_button_clicked',
		name: 'Unmark seller funds received cancel button clicked',
		...SNOWPLOW_EVENT_TYPE.CLICK,
	}),
	target: effects.snowplowEventFx,
});

import { PAGES } from '@core/constants';

import type { ErrorBehavior } from './model';

export const BEHAVIOUR = {
	ERROR: 'ERROR',
	GLOBAL_ERROR: 'GLOBAL_ERROR',
	IGNORE: 'IGNORE',
	LOGOUT: 'LOGOUT',
	WARNING: 'WARNING',
};

export const GLOBAL_ERROR_MESSAGE = 'We are experiencing some problems right now. Please try again later';

/**
 * Errors behaviour list
 */
export const BEHAVIOUR_LIST: ErrorBehavior[] = [
	{
		behaviour: BEHAVIOUR.IGNORE,
		codes: [/.*/],
		paths: [/dealership\/user\/logout/],
	},
	{
		behaviour: BEHAVIOUR.IGNORE,
		codes: [/^403$/],
		paths: [/dealer\/dealer-contacts\/.*\/confirmPhoneNumber/, /payments\/otp\/request/],
	},
	{
		behaviour: BEHAVIOUR.IGNORE,
		codes: [/^422$/, /^404$/],
		paths: [/transaction\/\d+\/transport-options/],
	},
	{
		behaviour: BEHAVIOUR.IGNORE,
		codes: [/^422$/, /^404$/],
		paths: [/transaction\/v2\/\d+\/transport-options/],
	},
	{
		behaviour: BEHAVIOUR.IGNORE,
		codes: [/^422$/, /^404$/, /^410$/],
		paths: [/transaction\/\d+\/transport/],
	},
	{
		behaviour: BEHAVIOUR.IGNORE,
		codes: [/^422$/, /^404$/, /^410$/],
		paths: [/transaction\/v2\/\d+\/transport/],
	},
	{
		behaviour: BEHAVIOUR.ERROR,
		codes: [/^422$/],
		paths: [/vehicles\/\d+\/update-market-price/],
	},
	{
		behaviour: BEHAVIOUR.GLOBAL_ERROR,
		codes: [/^5\d{2}$/],
		message: GLOBAL_ERROR_MESSAGE,
		paths: [/.*/], // Server error
	},
	{
		behaviour: BEHAVIOUR.LOGOUT,
		codes: [/^401$/, /^403$/],
		paths: [/.*/],
	},
	{
		behaviour: BEHAVIOUR.ERROR,
		codes: [/.*/],
		paths: [/dealership\/vehicles\/\d+/, /dealer\/dealers\/\d+$/, /premium\/users\/\d+/],
		redirect: PAGES.HOME.PATH,
	},
	{
		behaviour: BEHAVIOUR.ERROR,
		codes: [/^500$/, /^413$/],
		message: 'File is too large',
		paths: [/agent\/.+\/profilePicture\/update/],
	},
	{
		behaviour: BEHAVIOUR.WARNING,
		codes: [/^409$/],
		message: 'Prep already saved to enquiry',
		paths: [/prep-costs\/autoPrep/],
	},
	{
		behaviour: BEHAVIOUR.IGNORE,
		codes: [/^404$/, /^409$/],
		paths: [/payments\/payments\/manual\/create/],
	},
	{
		behaviour: BEHAVIOUR.IGNORE,
		codes: [/.*/],
		paths: [/kyc\/dealers\/\d+\/companyDetails/],
	},
	{
		behaviour: BEHAVIOUR.IGNORE,
		codes: [/^404$/],
		paths: [/kyc\/dealers\/\d+\/status\/logs/, /service-history\/services\/\d+\/prediction/],
	},
	{
		behaviour: BEHAVIOUR.IGNORE,
		codes: [/^4\d\d$/],
		paths: [/finance-settlement\/v1\/provider.+/],
	},
];

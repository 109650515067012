import { combine } from 'effector';

import * as agents from '../agents/internal';
import * as app from '../app/internal';
import * as enquiry from '../vehicle/internal';

import * as effects from './effects';
import type {
	HistoryLog,
	Payment,
	PostSaleOffer,
	PostSaleOfferDetails,
	PriceBreakdown,
	SellerFundsReceivedResponse,
} from './model';
import { PaymentType } from './model';
import {
	domain,
	getDescription,
	getFinanceAmountByUserType,
	resolveHasPaymentSucceeded,
	resolveNegativeEquity,
} from './utils';

export const $postSaleOffers = domain
	.store<PostSaleOfferDetails[]>([])
	.on(effects.getPostSaleOffersFx.doneData, (_, result) => {
		result.data.sort((a, b) => b.id - a.id);

		const closingBidOffer = result.data.find((o) => o.type === 'closing_bid'); // the newest closing_bid

		return result.data.map((item: PostSaleOffer) => ({
			...item,
			amendment: item.amount - (closingBidOffer?.amount || 0),
			brokerFeeBreakdown: item.priceBreakdown.find((i: PriceBreakdown) => i.type === 'broker_fee_amount'),
			dealerPriceBreakdown: item.priceBreakdown.find((i: PriceBreakdown) => i.type === 'dealer_amount'),
			financingPriceBreakdown: item.priceBreakdown.find((i: PriceBreakdown) => i.type === 'financing_amount'),
			motorwayProtectBreakdown: item.priceBreakdown.find((i: PriceBreakdown) => i.type === 'mechanical_guarantee'),
			sellerPriceBreakdown: item.priceBreakdown.find((i: PriceBreakdown) => i.type === 'seller_amount'),
			totalFeeBreakdown: item.priceBreakdown.find((i: PriceBreakdown) => i.type === 'total_fee_amount'),
			transportFeeBreakdown: item.priceBreakdown.find((i: PriceBreakdown) => i.type === 'transport_fee_amount'),
		}));
	})
	.reset([enquiry.$enquiryId, app.reset]);

export const $latestOffer = $postSaleOffers.map(
	(state: PostSaleOfferDetails[]): PostSaleOfferDetails | null => state[0] ?? null,
);

export const $hasLatestOffer = $latestOffer.map((offer) => Boolean(offer));

export const $newestClosingBid = $postSaleOffers.map(
	(state: PostSaleOfferDetails[]) => state.find((item) => item.type === 'closing_bid') ?? null,
);

export const $payments = domain
	.store<Payment[]>([])
	.on(effects.getPaymentsFx.doneData, (_, result) => result.data)
	.reset([enquiry.$enquiryId, app.reset]);

export const $auditLogs = domain
	.store<HistoryLog[]>([])
	.on(effects.getPaymentsHistoryLogFx.doneData, (_, result) => {
		result.data.sort((a, b) => new Date(b.eventIssued).getTime() - new Date(a.eventIssued).getTime());

		return result.data;
	})
	.reset([enquiry.$enquiryId, app.reset]);

export const $historyLog = combine([$auditLogs, agents.$allAgentsMap], ([auditLogs, agentsMap]) =>
	auditLogs.map((log: HistoryLog) => ({
		amount: log.amount,
		description: getDescription(log.kind, log.type),
		eventIssued: log.eventIssued,
		id: log.id,
		kind: log.kind,
		readableOrigin: log.origin === 'agent' ? agentsMap[Number(log.originIdentifier)] || log.origin : log.origin,
	})),
);

export const $enquiryFinanceStatuses = domain
	.store<Nilable<SellerFundsReceivedResponse>>(null)
	.on(effects.getEnquiryFinanceStatusesFx.doneData, (_, result) => result.data)
	.reset([enquiry.$enquiryId, app.reset]);

export const $hasSellerFundsReceived = combine(
	$enquiryFinanceStatuses,
	(sellerFundsReceivedStatus) => sellerFundsReceivedStatus?.financeStatus?.seller?.status === 'paid',
);

export const $hasNegativeEquity = combine([$enquiryFinanceStatuses, $latestOffer], resolveNegativeEquity);

export const $sellerFinanceAmount = combine($enquiryFinanceStatuses, (financeStatus) =>
	getFinanceAmountByUserType(financeStatus, 'seller'),
);

export const $hasDealerPaymentSucceeded = combine($payments, (payments) =>
	resolveHasPaymentSucceeded(payments, PaymentType.dealer_to_mw),
);

import { sample } from 'effector';

import { SLUGS } from '@motorway/mw-enquiry-states';

import { notifyOnDoneFx, notifyOnFailFx } from '@core/utils/side-effects';

import { contentService } from '../content';
import { vehicleService } from '../vehicle';

import * as effects from './effects';
import * as events from './events';

/**
 * Load payments section initial data
 */
sample({
	clock: events.init,
	target: [effects.getPostSaleOffersFx, effects.getPaymentsFx, effects.getPaymentsHistoryLogFx],
});

/**
 * Load funds received data on payments section init
 */
sample({
	clock: [events.init, events.refetchEnquiryFinanceStatuses],
	filter: ({ enquiry, isFeatureEnabled }) => {
		const isEnquiryInSoldDocsReviewed = enquiry.state.slug === SLUGS.SOLD_DOCUMENTS_REVIEWED;
		const isDealerOnPay = enquiry?.closingBid?.dealer.features.paymentsMVP === true;
		return isFeatureEnabled && isEnquiryInSoldDocsReviewed && isDealerOnPay;
	},
	fn: (_, enquiryId) => enquiryId,
	source: {
		enquiry: vehicleService.$enquiry,
		isFeatureEnabled: contentService.$negativeEquityV01Enabled,
	},
	target: effects.getEnquiryFinanceStatusesFx,
});

/**
 * Load funds received data after we mark/unmark funds as received
 */
sample({
	clock: [effects.markSellerFundsReceivedFx.done, effects.unmarkSellerFundsReceivedFx.done],
	fn: ({ params }) => params.enquiryId,
	target: events.refetchEnquiryFinanceStatuses,
});

/**
 * Refetch initial data after various actions
 */
sample({
	clock: [
		effects.createPostSaleOffersFx.done,
		effects.updateLatestOfferDescFx.done,
		effects.createManualPaymentFx.done,
	],
	fn: ({ params }) => params.enquiryId,
	target: events.init,
});

/**
 * Notify about sendNewOfferSMSFx events
 */
notifyOnDoneFx(effects.sendNewOfferSMSFx.done, () => 'Text message sent to seller');
notifyOnFailFx(effects.sendNewOfferSMSFx.fail, () => 'Could not send message to seller');

/**
 * generate OTP sms - dealer contact
 */
notifyOnFailFx(effects.generateOTPFx.fail, () => 'Could not send SMS code to dealer');

/**
 * update damage description
 * vehicle details - payments section
 */
notifyOnFailFx(effects.updateLatestOfferDescFx.fail, () => 'Could not update damage description');

/**
 * Notify about manual payment
 */
notifyOnDoneFx(effects.createManualPaymentFx.done, () => 'Payment marked as manually paid');

/**
 * Notify about making/unmaking seller funds as received
 */
notifyOnDoneFx(effects.markSellerFundsReceivedFx.done, () => 'Funds marked as received');
notifyOnFailFx(effects.markSellerFundsReceivedFx.fail, () => 'Could not mark funds as received');
notifyOnDoneFx(effects.unmarkSellerFundsReceivedFx.done, () => 'Seller funds unmarked');
notifyOnFailFx(effects.unmarkSellerFundsReceivedFx.fail, () => 'Could not unmark funds as received');

import type { AxiosError } from 'axios';
import type { Effect } from 'effector';

import type { RequestConfig } from '@core/http';
import { request } from '@core/http';

import type { FinanceSettlementQuery, GetFinanceProviderSlugQuery, GetFinanceProviderSlugResponse } from './model';
import { domain } from './utils';

export const getFinanceSettlementData: Effect<FinanceSettlementQuery, void, AxiosError> = domain.effect({
	handler: ({ providerSlug, ...query }: FinanceSettlementQuery) =>
		request({
			data: query,
			headers: {
				service: 'AGENT',
				'x-mway-user': query.authToken,
			},
			method: 'POST',
			url: `/finance-settlement/v1/settlement/${providerSlug}`,
		} as RequestConfig),
});

export const getFinanceProviderFx: Effect<GetFinanceProviderSlugQuery, GetFinanceProviderSlugResponse, AxiosError> =
	domain.effect({
		handler: (query: GetFinanceProviderSlugQuery) =>
			request({
				headers: {
					service: 'AGENT',
				},
				method: 'GET',
				url: `/finance-settlement/v1/provider?search=${query.financeCompanyName}`,
			} as RequestConfig),
	});

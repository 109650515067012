import type { Effect } from 'effector';
import { omit } from 'lodash-es';

import { TIME_24_HOURS } from '@core/constants';
import type { JSENDAPIListResponse, JSENDAPIResponse, RequestConfig } from '@core/http';
import { request } from '@core/http';
import type { ListResponse } from '@core/model';
import { pickAllowedParams } from '@core/utils/api';
import type { IDealerNote, NotesGetParams } from '@services/vehicle';

import type { IDealerStatus } from '../app';

import {
	ALLOWED_NOTES_PARAMS,
	DEFAULT_DEALER_STATUSES_PARAMS,
	DEFAULT_NOTES_PARAMS,
	SEND_DEALER_ACTIVATION_SMS,
} from './constants';
import type {
	AddSiteResponse,
	CreateAdditionalSiteAddressParam,
	CreateNoteParam,
	GetStatusLogsParams,
	IAdditionalSiteAddress,
	IDealer,
	IDealerContact,
	IDealerContactPermissionsUpdate,
	IDealerKycCompanyDetails,
	IDealerKycDetailsWithAssociates,
	IDealerKycStatusDetails,
	IDealerKycStatusLogs,
	IDealerStatusLogs,
	IDealerWithContactsAndPermissions,
	IKycStatuses,
	KycDetailsGetParams,
} from './model';
import { domain, parseKycCompanyDetails } from './utils';

/**
 * Get dealer by id
 */
export const getDealerFx: Effect<string | number, IDealerWithContactsAndPermissions> = domain.effect({
	handler: (dealerId) =>
		request({
			method: 'get',
			params: {
				include: 'contacts,permissions',
			},
			url: `/dealer/dealers/${dealerId}`,
		} as RequestConfig),
});

/**
 * Create dealer
 */
export const createDealerFx: Effect<Partial<IDealer>, IDealer> = domain.effect({
	handler: (data) =>
		request({
			data,
			method: 'post',
			url: '/premium/dealers',
		} as RequestConfig),
});

/**
 * Update dealer
 */
export const updateDealerFx: Effect<Partial<IDealer>, IDealer> = domain.effect({
	handler: (data) =>
		request({
			data: omit(data, ['invoicedCustomerId']),
			method: 'post',
			url: `/dealer/dealers/${data.id}/update`,
		} as RequestConfig),
});

/**
 * Delete dealer by id
 */
export const deleteDealerFx: Effect<string | number, IDealer> = domain.effect({
	handler: (dealerId) =>
		request({
			method: 'delete',
			url: `/premium/dealers/${dealerId}`,
		} as RequestConfig),
});

/**
 * Create dealer contact
 */
export const createDealerContactFx: Effect<Partial<IDealerContact>, IDealerContact> = domain.effect({
	handler: (data) =>
		request({
			data,
			method: 'post',
			url: '/premium/dealer-contacts',
		} as RequestConfig),
});

/**
 * Update dealer contact
 */
export const updateDealerContactFx: Effect<Partial<IDealerContact>, IDealerContact> = domain.effect({
	handler: (data) =>
		request({
			data,
			method: 'put',
			url: `/premium/dealer-contacts/${data.id}`,
		} as RequestConfig),
});

/**
 * Delete dealer contact
 */
export const deleteDealerContactFx: Effect<string | number, void> = domain.effect({
	handler: (id) =>
		request({
			method: 'delete',
			url: `/premium/dealer-contacts/${id}`,
		} as RequestConfig),
});

/**
 * Update dealer contact permissions
 */
export const updateDealerContactPermissionsFx: Effect<
	{ id: string | number; permissions: IDealerContactPermissionsUpdate },
	void
> = domain.effect({
	handler: ({ id, permissions }) =>
		request({
			data: permissions,
			method: 'put',
			url: `/dealer/dealer-contacts/${id}/permissions`,
		} as RequestConfig),
});

/**
 * Send activation SMS
 */
export const sendDealerActivationSMSFx: Effect<string | number, 'OK'> = domain.effect({
	handler: (id) =>
		request({
			loadingKey: `${SEND_DEALER_ACTIVATION_SMS}-${id}`,
			method: 'post',
			url: `/premium/dealer-contacts/${id}/sendActivationSms`,
		} as RequestConfig),
});

/**
 * Get vehicle notes by sort option
 */
export const getNotesFx: Effect<NotesGetParams, ListResponse<IDealerNote>> = domain.effect({
	handler: (params) =>
		request({
			method: 'get',
			params: pickAllowedParams({ ...DEFAULT_NOTES_PARAMS, ...params }, ALLOWED_NOTES_PARAMS),
			url: '/premium/dealer-notes',
		} as RequestConfig),
});

/**
 * Create note
 */
export const createNoteFx: Effect<CreateNoteParam, IDealerNote> = domain.effect({
	handler: (data) =>
		request({
			data,
			method: 'post',
			url: '/premium/dealer-notes',
		} as RequestConfig),
});

export const confirmDealerOtpPhoneNumberFx: Effect<any, any> = domain.effect({
	handler: (data) =>
		request({
			data: { verificationCode: data.verificationCode },
			method: 'post',
			url: `/dealer/dealer-contacts/${data.id}/confirmPhoneNumber`,
		} as RequestConfig),
});

/**
 * Get dealer status logs
 */
export const getStatusLogsFx: Effect<GetStatusLogsParams, JSENDAPIResponse<IDealerStatusLogs[]>> = domain.effect({
	handler: ({ id, ...rest }) =>
		request({
			method: 'get',
			params: { ...rest },
			url: `/premium-v3/dealerStatusLogs/byDealerId/${id}`,
		} as RequestConfig),
});

/**
 * Get dealer statuses
 */

export const getDealerStatusesFx: Effect<any, ListResponse<IDealerStatus>> = domain.effect({
	handler: (params = {}) =>
		request({
			cacheTTL: TIME_24_HOURS,
			method: 'get',
			params: {
				...DEFAULT_DEALER_STATUSES_PARAMS,
				...params,
			},
			url: '/premium/dealer-statuses',
		} as RequestConfig),
});

/**
 * Get all dealer statuses
 */
export const getAllDealerStatusesFx: Effect<void, ListResponse<IDealerStatus>> = domain.effect({
	handler: () =>
		request({
			cacheTTL: TIME_24_HOURS,
			method: 'get',
			url: '/premium/dealer-statuses',
		} as RequestConfig),
});

/**
 * Get kyc information
 */
export const getDealerKycDetailsFx: Effect<
	KycDetailsGetParams,
	JSENDAPIListResponse<IDealerKycCompanyDetails>
> = domain.effect({
	handler: ({ dealerId }) =>
		request({
			method: 'get',
			url: `/kyc/dealers/${dealerId}/companyDetails`,
		} as RequestConfig),
});

/**
 * Update kyc information
 */
export const updateKycDetailsFx: Effect<
	Partial<IDealerKycDetailsWithAssociates>,
	JSENDAPIListResponse<IDealerKycCompanyDetails>
> = domain.effect({
	handler: (data) =>
		request({
			data: parseKycCompanyDetails(data),
			method: 'post',
			url: `/kyc/dealers/${data.dealerId}/companyDetails`,
		} as RequestConfig),
});

/**
 * Get kyc statuses
 */
export const getKycStatusesFx: Effect<void, JSENDAPIListResponse<IKycStatuses>> = domain.effect({
	handler: () =>
		request({
			method: 'get',
			url: `/kyc/statuses`,
		} as RequestConfig),
});

/**
 * Get dealer kyc status
 */
export const getDealerKycStatusFx: Effect<number, JSENDAPIResponse<IDealerKycStatusDetails>> = domain.effect({
	handler: (dealerId) =>
		request({
			method: 'get',
			url: `/kyc/dealers/${dealerId}/status`,
		} as RequestConfig),
});

/**
 * Update dealer kyc status
 */
export const updateDealerKycStatusFx: Effect<{ dealerId: number; kycStatusDetails: IDealerKycStatusDetails }, void> =
	domain.effect({
		handler: ({ dealerId, kycStatusDetails }) =>
			request({
				data: { ...kycStatusDetails },
				method: 'patch',
				url: `/kyc/dealers/${dealerId}/status`,
			} as RequestConfig),
	});

/**
 * Get dealer pdf with kyc details
 */
export const getDealerPdfKycDetailsFx: Effect<{ dealerId: number }, JSENDAPIListResponse<string>> = domain.effect({
	handler: ({ dealerId }) =>
		request({
			method: 'post',
			url: `/kyc/dealers/${dealerId}/kyc/generate-pdf`,
		} as RequestConfig),
});

/**
 * Get dealer status logs
 */
export const getDealerKycStatusLogsFx: Effect<number, JSENDAPIResponse<IDealerKycStatusLogs>> = domain.effect({
	handler: (dealerId) =>
		request({
			method: 'get',
			url: `/kyc/dealers/${dealerId}/status/logs`,
		} as RequestConfig),
});

/**
 * Get additional site addresses
 */
export const getAdditionalSiteAddressesFx: Effect<number, IAdditionalSiteAddress> = domain.effect({
	handler: (dealerId) =>
		request({
			method: 'get',
			url: `/dealer-registry/dealer/${dealerId}/sites`,
		} as RequestConfig),
});

/**
 * Create additional site address
 */
export const createAdditionalSiteAddressFx: Effect<
	{ dealerId: number; params: CreateAdditionalSiteAddressParam },
	AddSiteResponse
> = domain.effect({
	handler: ({ dealerId, params }) =>
		request({
			data: params,
			method: 'post',
			url: `/dealer-registry/dealer/${dealerId}/site`,
		} as RequestConfig),
});

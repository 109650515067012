import { combine } from 'effector';

import * as documentsService from '@services/documents/internal';
import * as vehicleService from '@services/vehicle/internal';

export const $financeData = combine(vehicleService.$enquiry, documentsService.$documentsMatch, (enquiry, documents) => {
	const financeAgreement = Array.isArray(enquiry.limitations?.financeAgreements)
		? enquiry.limitations?.financeAgreements[0]
		: null;
	const fslDoc = documents.finance_settlement_letter ?? null;

	const financeInfo = {
		agreementDoc: fslDoc?.fields.find((f) => f.fieldName === 'agreementNumber')?.value ?? null,
		agreementFromHPI: financeAgreement?.reference ?? null,
		companyDoc: fslDoc?.fields.find((f) => f.fieldName === 'companyName')?.value ?? '',
		companyFromHPI: financeAgreement?.company ?? null,
	};

	// Get details from HPI or if it doesn't exist, get from OCR of Finance Doc
	return {
		agreementNumber: financeInfo.agreementFromHPI || financeInfo.agreementDoc || null,
		financeCompany: financeInfo.companyFromHPI || financeInfo.companyDoc || null,
	};
});

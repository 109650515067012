import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Toolbar } from '@mui/material';
import { useUnit } from 'effector-react';
import qs from 'query-string';

import { Button } from '@motorway/mw-highway-code';

import { BACKEND_URL } from '@core/app-state';
import { ReactComponent as OneLoginLogo } from '@core/components/ChristmasTheme/assets/onelogin-logo.svg';
import { ChristmasLogin } from '@core/components/ChristmasTheme/ChristmasLogin';
import { MotorwayLogo } from '@core/components/MotorwayLogo';
import { authService } from '@core/services/auth';
import { contentService } from '@core/services/content';

import styles from './SignIn.module.scss';

export const SignIn = () => {
	const location = useLocation();

	const [isLoggedIn, christmasTheme] = useUnit([authService.$isLoggedIn, contentService.$christmasTheme]);

	// Append the URL the user came from, so they are redirected to it after the login
	const { url } = qs.parse(location.search);
	const origin = encodeURIComponent(window.location.origin || `${window.location.protocol}//${window.location.host}`);
	const redirectUri = url ? `&redirectUri=${url}` : '';
	// @ts-ignore
	const oneLoginSignInUrl = `${BACKEND_URL}/dealership/user/auth/oneLogin?origin=${origin}${redirectUri}`;

	if (isLoggedIn) {
		return <Navigate to={{ pathname: '/' }} />;
	}

	return (
		<>
			{christmasTheme ? (
				<ChristmasLogin oneLoginSignInUrl={oneLoginSignInUrl} />
			) : (
				<div className={styles.wrapper}>
					<div className={styles.chevronBackground} />
					<Toolbar className={styles.header}>
						<MotorwayLogo />
					</Toolbar>
					<div className={styles.card}>
						<h1 className={styles.title} data-test="title">
							Agent Dashboard
						</h1>
						<Button
							as="a"
							data-test="oneLoginButton"
							href={oneLoginSignInUrl}
							label={
								<div className={styles.button}>
									Sign in with
									<OneLoginLogo />
								</div>
							}
						/>
					</div>
				</div>
			)}
		</>
	);
};

import type { UserType } from '@core/model';

import type { IVehicle } from '../vehicle/internal';

import type { PAYMENT_STATUS } from './constants';

export type PriceBreakdownType =
	| 'financing_amount'
	| 'seller_amount'
	| 'dealer_amount'
	| 'broker_fee_amount'
	| 'transport_fee_amount'
	| 'mechanical_guarantee'
	| 'total_fee_amount';

export type PostSaleOfferState = 'approved' | 'pending' | 'rejected';
export type PostSaleOfferType = 'closing_bid' | 'counter_offer';

export enum PaymentStatus {
	done = 'done',
	errored = 'errored',
	failed = 'failed',
	initial = 'initial',
	pending = 'pending',
}

export enum PaymentType {
	dealer_to_mw = 'dealer_to_mw',
	fee = 'fee',
	finance_account_to_finance_house = 'finance_account_to_finance_house',
	mw_to_finance_account = 'mw_to_finance_account',
	mw_to_finance_house = 'mw_to_finance_house',
	mw_to_seller = 'mw_to_seller',
	seller_to_mw = 'seller_to_mw',
}

export type ApprovalUserType = Exclude<UserType, 'agent'>;

export type Approval<K extends ApprovalUserType = ApprovalUserType> = {
	createdAt: string;
	id: number;
	updatedAt: string;
	userType: K;
};

export type PriceBreakdown<K extends PriceBreakdownType = PriceBreakdownType> = {
	amount: number;
	amountNet?: number;
	amountVat?: number;
	createdAt: string;
	id: number;
	type: K;
	updatedAt: string;
	vatRate?: number;
} & (K extends 'mechanical_guarantee'
	? {
			detail: {
				promotionalRate: boolean;
			};
		}
	: {
			detail?: never;
		});

export type PostSaleOffer = {
	amendment?: number;
	amount: number;
	approvals: Approval[];
	closingBidId: number;
	createdAt: string;
	damageDescription?: string;
	enquiryId: number;
	id: number;
	priceBreakdown: PriceBreakdown[];
	state: PostSaleOfferState;
	type: PostSaleOfferType;
	userId: number;
	userType: UserType;
};

export interface PostSaleOfferDetails extends PostSaleOffer {
	brokerFeeBreakdown?: PriceBreakdown;
	dealerPriceBreakdown?: PriceBreakdown;
	financingPriceBreakdown?: PriceBreakdown;
	motorwayProtectBreakdown?: PriceBreakdown;
	sellerPriceBreakdown?: PriceBreakdown;
	totalFeeBreakdown?: PriceBreakdown;
	transportFeeBreakdown?: PriceBreakdown;
}

export type CreatePostSaleOfferPayload = {
	amount: number;
	enquiryId: IVehicle['id'];
};

export type CreateManualPaymentPayload = {
	enquiryId: IVehicle['id'];
	manualPayments: Array<{
		amount: number;
		reference: string;
		type: PaymentType;
	}>;
	sendEmails: boolean;
};

export type CreateManualPaymentResponse =
	| {
			manualPaymentRecords: ManualPaymentRecord[];
			status: 'success';
	  }
	| {
			manualPayments: Array<{
				amount: number;
				reference: string;
				type: PaymentType;
			}>;
			message: string;
	  };

export type UpdatePostSaleOfferDescPayload = {
	damageDescription: string;
	enquiryId: IVehicle['id'];
};

export type Payment = {
	isManual?: boolean;
	status?: PaymentStatus;
	type?: PaymentType;
};

export type ManualPaymentRecord = {
	[key: string]: any;
	isManual: boolean;
};

export type PaymentStatusType = (typeof PAYMENT_STATUS)[keyof typeof PAYMENT_STATUS];

export type HistoryLogKind =
	| 'initial_offer_created'
	| 'counter_offer_created'
	| 'finance_paid_manually'
	| 'manually_paid'
	| 'sms_for_offer_approval_sent'
	| 'offer_approved'
	| 'final_price_agreed'
	| 'payment_taken_from_dealers_wallet'
	| 'payment_taken_from_next_gear_wallet'
	| 'payment_to_motorway_dealer_account_found'
	| 'payment_to_motorway_registered'
	| 'payment_to_motorway_accepted'
	| 'payment_to_motorway_manually_approved'
	| 'payment_to_motorway_succeeded'
	| 'payment_to_seller_beneficiary_found'
	| 'payment_to_seller_registered'
	| 'payment_to_seller_accepted'
	| 'payment_to_seller_succeeded'
	| 'payment_to_finance_company_succeeded'
	| 'payment_confirmation_to_dealer_sent'
	| 'payment_confirmation_to_seller_sent'
	| 'payout'
	| 'payin'
	| 'payment_failed'
	| 'payment_to_fee_account_succeeded'
	| 'next_gear_credit_approved'
	| 'next_gear_funding_failed'
	| 'seller_negative_equity_funds_marked_received'
	| 'seller_negative_equity_funds_received_confirmation_sent'
	| 'seller_negative_equity_funds_unmarked_received';

export type HistoryLogOrigin = 'system' | 'seller' | 'dealer' | 'agent';

export type HistoryLog = {
	accountNumber?: string;
	amount: number;
	enquiryId: number;
	eventDelivered: string;
	eventIssued: string;
	id: number;
	isBusiness?: boolean;
	kind: HistoryLogKind;
	name?: string;
	offerAmount: number;
	offerId: number;
	origin: HistoryLogOrigin;
	originIdentifier: string;
	payload: Record<string, any>;
	reference?: string;
	sender: string;
	senderId: string;
	sortCode?: string;
	type?: PaymentType;
};

export type FinanceStatusUserType = Exclude<UserType, 'agent' | 'dealer'>;

export type FinanceStatus = {
	amount: number;
	status: 'paid' | 'unpaid';
};

export type SellerFundsReceivedPayload = {
	enquiryId: number;
};

export type SellerFundsReceivedResponse = {
	enquiryId: number;
	financeStatus: Record<FinanceStatusUserType, FinanceStatus>;
	isNegativeEquity: boolean;
	postSaleOfferId: number;
	sellerId: number;
};

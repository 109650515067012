import { createTheme } from '@mui/material';

export const colours = {
	black: '#242424',
	blue: '#001E86',
	blueActive: '#264098',
	blueDisabled: '#99a5cf',
	blueHover: '#0d298c',
	blueLoading: '#001E86',
	borderGrey: '#E6E6E6',
	darkGrey: '#3B3B3B',
	error: '#940000',
	green: '#58D5A7',
	grey: '#E2E1D9',
	lightBlue: '#addde4',
	offBlack: '#2C2C2C',
	pipeGrey: '#C2C2C2',
	secondaryBlue: '#e6f4fa',
	semanticSuccess25: '#006626',
	tableGrey: '#f2f2f2',
	vrmYellow: '#FFE14C',
	warning: '#AC4600',
	white: '#fff',
	yellow: '#FFE14C',
};

const defaultFonts = {
	color: colours.black,
	fontSize: '1rem',
	lineHeight: '1.5rem',
};

export const theme = createTheme({
	breakpoints: {
		keys: ['xs', 'sm', 'md', 'lg', 'xl'],
		values: {
			lg: 3,
			md: 2,
			sm: 1,
			xl: 1920,
			xs: 0,
		},
	},
	components: {
		MuiAutocomplete: {
			styleOverrides: {
				input: {
					border: 'none',
					paddingLeft: '16px !important',
				},
				option: {
					height: 48,
				},
				paper: {
					"& .MuiAutocomplete-option[aria-selected='true']": {
						backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
					},
				},
				popper: {
					top: '2px !important',
				},
				popupIndicator: {
					transform: 'none',
				},
				root: {
					'& .MuiAutocomplete-clearIndicator': {
						right: '35px',
					},
					'& .MuiAutocomplete-popupIndicator': {
						'& svg': {
							'& path': {
								fill: colours.black,
							},
							height: '19px',
							width: '19px',
						},
						position: 'absolute',
						right: 10,
						top: -10,
					},
					'& .MuiFormControl-root': {
						margin: 0,
					},
					'&:focus': {
						borderColor: colours.pipeGrey,
					},
					'&:hover': {
						borderColor: colours.pipeGrey,
					},
					'&[disabled]': {
						borderColor: colours.grey,
					},
					backgroundColor: colours.white,
					border: '2px solid',
					borderColor: colours.borderGrey,
					borderRadius: 12,
					boxSizing: 'border-box',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: 18,
					fontWeight: 'bold',
					height: 48,
					lineHeight: 1.5,
					textTransform: 'none',
				},
				textPrimary: {
					'&.Mui-disabled': {
						backgroundColor: colours.grey,
					},
					'&:active': {
						backgroundColor: colours.darkGrey,
						boxShadow: 'none',
					},
					'&:focus': {
						boxShadow: `0 0 4px 0 ${colours.blue}`,
					},
					'&:hover': {
						backgroundColor: colours.offBlack,
					},
					backgroundColor: colours.black,
					border: 'none',
					borderRadius: 100,
					boxShadow: 'none',
					color: colours.white,
					fontSize: 18,
					fontWeight: 'bold',
					marginBottom: 16,
					textTransform: 'none',
					width: '100%',
				},
				textSecondary: {
					'&:focus': {
						boxShadow: `0 0 4px 0 ${colours.grey}`,
					},
					'&:hover, &:active': {
						backgroundColor: colours.white,
						borderColor: colours.pipeGrey,
					},
					backgroundColor: colours.white,
					border: '2px solid',
					borderColor: colours.grey,
					borderRadius: 100,
					boxShadow: 'none',
					color: colours.black,
					fontSize: 18,
					fontWeight: 'bold',
					outline: 'none',
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true, // No more ripple, on the whole application 💣!
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					maxWidth: 'inherit',
					padding: '48px',
					width: 'auto',
				},
				paperFullWidth: {
					width: 'calc(100% - 64px)',
				},
				paperWidthSm: {
					maxWidth: '960px',
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					color: colours.black,
					fontSize: '1.5rem',
					fontWeight: 'bold',
					lineHeight: '2.5rem',
					marginBottom: '16px',
					marginTop: 0,
					padding: 0,
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					borderRadius: 12,
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					marginBottom: 24,
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					'&.Mui-error': {
						color: colours.error,
					},
					'&[color=warning]': {
						color: colours.warning,
					},
					fontSize: '0.75rem',
					height: 24,
					margin: 0,
					overflow: 'visible',
					whiteSpace: 'nowrap',
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					'&.Mui-error': {
						color: colours.error,
					},
					'&.Mui-error.Mui-focused': {
						color: colours.error,
					},
					'&.Mui-focused': {
						color: colours.black,
					},
					'&.MuiFormLabel-colorWarning': {
						color: colours.warning,
					},
					color: colours.black,
					fontWeight: 600,
					lineHeight: '1.5rem',
					marginBottom: 8,
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				input: {
					'&::-webkit-input-placeholder': {
						opacity: '0.5 !important',
						transition: 'none',
					},
					'&:focus': {
						borderColor: colours.pipeGrey,
					},
					'&:hover': {
						borderColor: colours.pipeGrey,
					},
					'&[disabled]': {
						borderColor: colours.grey,
					},
					backgroundColor: '#fff',
					border: '2px solid',
					borderColor: colours.borderGrey,
					borderRadius: 12,
					boxSizing: 'border-box',
					height: 48,
					padding: '0 16px',
				},
				inputTypeSearch: {
					paddingRight: '40px',
				},
				multiline: {
					'& textarea': {
						padding: '12px 16px',
						resize: 'none',
					},
					height: 'auto',
				},
				root: {
					'&.MuiInputBase-fullWidth': {
						flexGrow: 1,
						width: 'auto',
					},
					'&:after': {
						display: 'none',
					},
					'&:before': {
						display: 'none',
					},
					borderTopLeftRadius: 4,
					borderTopRightRadius: 4,
					flexShrink: 0,
					height: 48,
					'label+&': {
						marginTop: 0,
					},
					width: 424,
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						'&:hover': {
							backgroundColor: 'rgba(0, 0, 0, 0.08);',
						},
						backgroundColor: 'rgba(0, 0, 0, 0.08);',
					},
					'&:focus': {
						backgroundColor: 'rgba(0, 0, 0, 0.05);',
					},
					'&:hover': {
						backgroundColor: 'rgba(0, 0, 0, 0.04);',
					},
					height: 48,
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: 12,
				},
			},
		},
		MuiSelect: {
			defaultProps: {
				variant: 'standard',
			},
			styleOverrides: {
				nativeInput: {
					'&+svg': {
						'& path': {
							fill: colours.black,
						},
						fontSize: 32,
						height: '19px',
						position: 'absolute',
						right: 18,
						top: 'calc(50% - 9.5px)',
						width: '19px',
					},
					'&+svg.Mui-disabled': {
						'& path': {
							fill: 'inherit',
						},
					},
				},
				select: {
					'&.MuiInput-input': {
						'&:focus': {
							background: '#fff',
						},
						paddingRight: '47px',
					},
					borderRadius: 12,
					height: 48,
					lineHeight: '44px',
					minHeight: '1.1876em',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				},
			},
		},
		MuiSwitch: {
			styleOverrides: {
				colorPrimary: {
					'&.Mui-checked': {
						'& + .MuiSwitch-track': {
							'&::before': {
								color: colours.white,
								content: "'Yes'",
								left: 10,
								position: 'absolute',
								top: 4,
							},
							backgroundColor: colours.green,
							border: 'none',
							boxShadow: 'none',
							opacity: 1,
						},
						color: colours.white,
						transform: 'translateX(32px)',
					},
					'&.Mui-disabled.Mui-checked + .MuiSwitch-track': {
						backgroundColor: 'rgba(0, 0, 0, 0.38)',
					},
				},
				root: {
					'&:hover .MuiSwitch-thumb, &:hover .MuiSwitch-track::before': {
						transform: 'scale(1.05)',
					},
					height: 32,
					margin: 0,
					padding: 0,
					width: 'auto',
				},
				switchBase: {
					'&.Mui-disabled.Mui-checked': {
						color: colours.white,
					},
					left: 3,
					padding: 1,
					top: 3,
				},
				thumb: {
					'&::before': {
						content: "'No'",
						fontSize: '0.875rem',
						left: 8 * 4,
						lineHeight: '1.5rem',
						position: 'absolute',
					},
					boxShadow: 'none',
					height: 24,
					position: 'relative',
					width: 24,
				},
				track: {
					backgroundColor: colours.grey,
					border: `1px solid ${colours.grey}`,
					borderRadius: 8 * 3,
					opacity: 1,
					width: 8 * 8,
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					'&:hover .actions-buttons > *': {
						opacity: 1,
					},
				},
			},
		},
	},
	palette: {
		background: {
			default: colours.white,
		},
		common: {
			black: colours.black,
			white: colours.white,
		},
		primary: {
			main: colours.blue,
		},
		secondary: {
			main: colours.white,
		},
	},
	typography: {
		body1: defaultFonts,
		body2: defaultFonts,
		caption: defaultFonts,
		fontFamily: [
			'NewTransport',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		fontSize: 16,
		h1: defaultFonts,
		h2: {
			color: colours.black,
			fontSize: '1.125rem',
			fontWeight: 'normal',
			lineHeight: '1.6875rem',
		},
		h3: defaultFonts,
		h4: {
			color: colours.black,
			fontSize: '2rem',
			fontWeight: 'bold',
			lineHeight: '3rem',
		},
		h5: {
			color: colours.black,
			fontSize: '1.5rem',
			fontWeight: 'bold',
			lineHeight: '2.5rem',
			marginBottom: 16,
			marginTop: 0,
		},
		htmlFontSize: 16,
	},
});

import * as app from '../app/internal';
import { page } from '../vehicle/internal';

import * as effects from './effects';
import type { DealerSiteDeliveryTransport, DeliveryTransport, DeliveryTransportOptions } from './model';
import { domain } from './utils';

/**
 * Delivery - transport
 */
export const $delivery = domain
	.store<DeliveryTransport>({})
	.on(effects.getDeliveryTransportFx.doneData, (_, data) => data)
	.on(effects.saveDeliveryFx.doneData, (state, data) => ({ ...state, ...data }))
	.on(effects.cancelDeliveryFx.doneData, () => ({ price: 0, transportType: 'no-delivery' }))
	.reset([app.reset, page.reset]);

/**
 * Delivery - transport v2
 */
export const $deliverySite = domain
	.store<DealerSiteDeliveryTransport>({})
	.on(effects.getDealerSiteDeliveryTransportFx.doneData, (_, data) => data)
	.on(effects.saveDeliveryFx.doneData, (state, data) => ({ ...state, ...data }))
	.on(effects.cancelDeliveryFx.doneData, () => ({ price: 0, transportType: 'no-delivery' }))
	.reset([app.reset, page.reset]);

/**
 * Delivery - transport options
 */
export const $deliveryTransportOptions = domain
	.store<DeliveryTransportOptions | null>(null)
	.on(effects.getDeliveryTransportOptionsFx.doneData, (_, data) => data)
	.reset([app.reset, page.reset]);

/**
 * Delivery - failed transport options error
 */
export const $deliveryTransportOptionsError = domain
	.store<string | null>(null)
	.on(effects.getDeliveryTransportOptionsFx.failData, (_, data) => data?.response?.data?.message)
	.reset([app.reset, page.reset]);

/**
 * Delivery - transport options v2
 */
export const $dealerSiteDeliveryOptions = domain
	.store<any | null>(null)
	.on(effects.getDealerSiteTransportOptionsFx.doneData, (_, data) => data)
	.reset([app.reset, page.reset]);

/**
 * Delivery - failed transport options error v2
 */
export const $dealerSiteDeliveryOptionsError = domain
	.store<string | null>(null)
	.on(effects.getDealerSiteTransportOptionsFx.failData, (_, data) => data?.response?.data?.message)
	.reset([app.reset, page.reset]);

/**
 * List of all transport providers
 */
export const $transportProviders = domain
	.store<any>([])
	.on(effects.getTransportProvidersFx.doneData, (_, data) => data);

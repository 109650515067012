import type { Domain } from 'effector';

import { domain as globalDomain } from '@core/domain';

import type { HistoryLogKind, Payment, PaymentType, PostSaleOfferDetails, SellerFundsReceivedResponse } from './model';
import { PaymentStatus } from './model';

export const domain: Domain = globalDomain.domain('@paymentService');

const PAID_MANUALLY_PREFIX = 'Paid manually';

export const getDescription = (kind: HistoryLogKind, type?: PaymentType): string => {
	if (kind === 'manually_paid' && type) {
		const manuallyPaidCases: Partial<Record<PaymentType, string>> = {
			dealer_to_mw: `${PAID_MANUALLY_PREFIX} - From wallet`,
			fee: `${PAID_MANUALLY_PREFIX} - Fees`,
			finance_account_to_finance_house: `${PAID_MANUALLY_PREFIX} - Finance`,
			mw_to_seller: `${PAID_MANUALLY_PREFIX} - To seller`,
			seller_to_mw: `${PAID_MANUALLY_PREFIX} - From seller`,
		};
		return manuallyPaidCases[type] || PAID_MANUALLY_PREFIX;
	}
	const cases: Partial<Record<HistoryLogKind, string>> = {
		counter_offer_created: 'Price reduction submitted',
		initial_offer_created: 'Sold - Documents reviewed',
		manually_paid: PAID_MANUALLY_PREFIX,
		next_gear_credit_approved: 'Funding approved by NextGear Capital',
		next_gear_funding_failed: 'Funding payment failed',
		offer_approved: 'Price reduction accepted',
		payment_taken_from_dealers_wallet: 'Payment taken from dealer’s wallet',
		payment_taken_from_next_gear_wallet: 'Payment taken from NextGear’s wallet',
		payment_to_fee_account_succeeded: 'Payment for fees complete',
		payment_to_finance_company_succeeded: 'Finance paid manually',
		payment_to_motorway_succeeded: 'Payment taken from dealer’s wallet',
		payment_to_seller_succeeded: 'Payment sent to seller',
		seller_negative_equity_funds_marked_received: 'Seller funds received',
		seller_negative_equity_funds_received_confirmation_sent: 'Confirmation email sent to seller',
		seller_negative_equity_funds_unmarked_received: 'Seller funds unmarked',
		sms_for_offer_approval_sent: 'SMS sent for approval',
	};

	return cases[kind] || kind;
};

export const getFinanceAmountByUserType = (
	data: Nilable<SellerFundsReceivedResponse>,
	type: keyof SellerFundsReceivedResponse['financeStatus'],
) => data?.financeStatus?.[type]?.amount || 0;

export const resolveNegativeEquity = ([sellerFundsReceivedStatus, latestOffer]: [
	Nilable<SellerFundsReceivedResponse>,
	PostSaleOfferDetails,
]): boolean => {
	/**
	 * The negative equity status is determined by the following rules:
	 * 1. If the API response is available, use the value from the API response
	 * 2. If the API response is not available, use the value from the latest offer
	 *
	 * This is important, as the API response is available only after the vehicle reaches payment states
	 * but other parts of the application need to know the negative equity status before that.
	 *
	 * Also in case of FF disabled, we need to use the "old way" of determining the negative equity status
	 */
	const financeAmount = latestOffer?.financingPriceBreakdown?.amount ?? 0;
	const latestOfferAmount = latestOffer?.amount ?? 0;

	const hasNegativeEquityFromApi = sellerFundsReceivedStatus?.isNegativeEquity;
	const hasNegativeEquityFromLatestOffer = financeAmount > latestOfferAmount;

	return hasNegativeEquityFromApi ?? hasNegativeEquityFromLatestOffer;
};

export const resolveHasPaymentSucceeded = (payments: Payment[], type: PaymentType) => {
	const payment = payments.find((p) => p.type === type);
	return payment?.status === PaymentStatus.done;
};

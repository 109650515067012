import './side-effects';

import {
	cancelDeliveryFx,
	getDealerSiteDeliveryTransportFx,
	getDealerSiteTransportOptionsFx,
	getDeliveryTransportFx,
	getDeliveryTransportOptionsFx,
	getTransportProvidersFx,
	saveDeliveryFx,
} from './effects';
import {
	$dealerSiteDeliveryOptions,
	$dealerSiteDeliveryOptionsError,
	$delivery,
	$deliverySite,
	$deliveryTransportOptions,
	$deliveryTransportOptionsError,
	$transportProviders,
} from './store';

export type { DeliveryTransport, DeliveryTransportOptions, TransportOption, TransportProvider } from './model';

export const deliveryService = {
	$dealerSiteDeliveryOptions,
	$dealerSiteDeliveryOptionsError,
	$delivery,
	$deliverySite,
	$deliveryTransportOptions,
	$deliveryTransportOptionsError,
	$transportProviders,
	cancelDeliveryFx,
	getDealerSiteDeliveryTransportFx,
	getDealerSiteTransportOptionsFx,
	getDeliveryTransportFx,
	getDeliveryTransportOptionsFx,
	getTransportProvidersFx,
	saveDeliveryFx,
};

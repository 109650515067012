import type { AxiosError } from 'axios';
import type { Effect } from 'effector';

import type { PhotoInstance } from '@core/containers/PhotoEditor';
import type { RequestConfig } from '@core/http';
import { request } from '@core/http';
import type { EnhancedEffect } from '@core/utils/effects';
import { enhancedEffect } from '@core/utils/effects';
import { photoDocsUpdateRequestConfig } from '@core/utils/photo-docs';
import type {
	BeneficiariesDocument,
	DeletePhotoDocResponse,
	GetBeneficiaryResponse,
	MatchDocument,
	PhotoDoc,
	StateDocument,
	UploadPhotoDocParams,
	UploadPhotoDocResponse,
} from '@core/utils/photo-docs.model';

import type { UpdateDocumentMetaPayload, UpdateDocumentStatePayload, UpdateDocumentStateResponse } from './model';
import { domain } from './utils';

/**
 * Get beneficiary - bank details
 */
export const getBeneficiaryEx: EnhancedEffect<string | number, GetBeneficiaryResponse, AxiosError> = enhancedEffect({
	domain,
	handler: (enquiryId) =>
		request({
			data: { enquiryId },
			method: 'POST',
			url: '/vehicle/documents/bankDetails/beneficiary',
		} as RequestConfig),
	name: 'getBeneficiaryFx',
});

/**
 * Get documents
 */
export const getDocumentsEx: EnhancedEffect<string | number, PhotoInstance[], AxiosError> = enhancedEffect({
	domain,
	handler: (enquiryId) =>
		request({
			method: 'get',
			params: { enquiryId },
			url: '/vehicle/documents',
		} as RequestConfig),
	name: 'getDocumentsFx',
});

/**
 * Get state of documents
 */
export const getStateDocumentsEx: EnhancedEffect<string | number, StateDocument[], AxiosError> = enhancedEffect({
	domain,
	handler: (enquiryId) =>
		request({
			method: 'get',
			params: { enquiryId },
			url: '/vehicle/documents/state',
		} as RequestConfig),
	name: 'getStateDocumentsFx',
});

/**
 * Get match of documents
 */
export const getMatchDocumentsEx: EnhancedEffect<string | number, MatchDocument, AxiosError> = enhancedEffect({
	domain,
	handler: (enquiryId) =>
		request({
			method: 'get',
			params: { enquiryId },
			url: '/vehicle/documents/match',
		} as RequestConfig),
	name: 'getMatchDocumentsFx',
});

/**
 * Get beneficiaries of documents
 */
export const getBeneficiariesDocumentsEx: EnhancedEffect<string | number, BeneficiariesDocument, AxiosError> =
	enhancedEffect({
		domain,
		handler: (enquiryId) =>
			request({
				method: 'get',
				url: `/payments/beneficiaries/${enquiryId}/exists`,
			} as RequestConfig),
		name: 'getBeneficiariesDocumentsFx',
	});

/**
 * Upload document
 */
export const uploadDocumentEx: EnhancedEffect<UploadPhotoDocParams, UploadPhotoDocResponse, AxiosError> =
	enhancedEffect({
		domain,
		handler: ({ file, modified, pageNumber, photo, useUploadDocumentsV2, vehicleId }) =>
			request({
				...photoDocsUpdateRequestConfig({ file, modified, pageNumber, photo, vehicleId }),
				onUploadProgress: (progressEvent) => uploadDocumentEx.setProgress(progressEvent),
				url: `/api/${useUploadDocumentsV2 ? 'v2' : 'v1'}/documents`,
			} as RequestConfig),
		name: 'uploadDocumentFx',
		setKey: ({ idx, photo }) => `${photo.name}-${idx}`,
	});

/**
 * Delete document
 */
export const deleteDocumentFx: Effect<Partial<PhotoDoc>, DeletePhotoDocResponse, AxiosError> = domain.effect({
	handler: ({ id: documentId }) =>
		request({
			data: { documentId },
			method: 'POST',
			url: '/vehicle/documents/delete',
		} as RequestConfig),
});

/**
 * update document meta
 */
export const updateDocumentMetaFx: Effect<UpdateDocumentMetaPayload, UpdateDocumentMetaPayload, AxiosError> =
	domain.effect({
		handler: (payload) =>
			request({
				data: payload,
				method: 'post',
				url: '/vehicle/documents/meta',
			} as RequestConfig),
	});

/**
 * update document state
 */
export const updateDocumentStateFx: Effect<UpdateDocumentStatePayload, UpdateDocumentStateResponse, AxiosError> =
	domain.effect({
		handler: (payload) =>
			request({
				data: payload,
				method: 'post',
				url: '/vehicle/documents/review',
			} as RequestConfig),
	});

/**
 * update document states
 */
export const updateDocumentStatesFx: Effect<UpdateDocumentStatePayload[], UpdateDocumentStateResponse[], AxiosError> =
	domain.effect({
		handler: async (payloads) => {
			const requests = payloads.map((data) => updateDocumentStateFx(data));
			const response = await Promise.all(requests);
			await getStateDocumentsEx.fx(payloads[0].enquiryId);
			return response;
		},
	});

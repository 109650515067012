import type {
	GAEvent,
	GalleryClickEvent,
	ImageResupplySelected,
	ManualPayment,
	PhotoSectionEvent,
	QAValidationWarningEvent,
	RequestAdditionalDocResupply,
	RestorePhotoFromArchiveEvent,
} from './model';
import { domain } from './utils';

/**
 * GA4 - Generic event
 */
export const sendGA4Event = domain.event<GAEvent>();

/**
 * GA4 - Event including vehicle id and state
 */
export const sendGA4EnquiryEvent = domain.event<GAEvent>();

/**
 * GA4 - Time on page event
 */
export const sendGA4TimeOnPageEvent = domain.event<string>();

/**
 * Analytics event - Photo action
 */
export const sendPhotoActionEvent = domain.event<PhotoSectionEvent>();

/**
 * Analytics event - Restore photo from archive
 */
export const sendRestorePhotoFromArchiveEvent = domain.event<RestorePhotoFromArchiveEvent>();

/**
 * Analytics event - Gallery opened
 */
export const sendGalleryOpenEvent = domain.event();

/**
 * Analytics event - Gallery click
 */
export const sendGalleryClickEvent = domain.event<GalleryClickEvent>();

/**
 * Analytics event - QA validation warning
 */
export const sendQAValidationWarningEvent = domain.event<QAValidationWarningEvent>();

/**
 * Analytics event - QA validation overridden
 */
export const sendQAValidationOverriddenEvent = domain.event();

/**
 * Analytics event - Request resupply modal open
 */
export const sendRequestResupplyModalOpenEvent = domain.event();

/**
 * Analytics event - Resupply requested
 */
export const sendResupplyRequestedEvent = domain.event();

/**
 * Analytics event - Send Image Resupply Selected
 */
export const sendImageResupplySelectedEvent = domain.event<ImageResupplySelected>();

/**
 * Analytics event - Resupply Docs Request
 */
export const sendResupplyDocsRequestEvent = domain.event<RequestAdditionalDocResupply>();

/**
 * Analytics event - Manual payment click
 */
export const manualPaymentEventClick = domain.event<ManualPayment>();

/**
 * Analytics event - Manual payment cta clicked
 */
export const manualPaymentEventCtaClicked = domain.event<ManualPayment>();

/**
 * Analytics event - KYC - Download PDF clicked
 */
export const downloadKycPdfEventClicked = domain.event();

/**
 * Analytics event - KYC - Download PDF - Modal Confirmation clicked
 */
export const downloadKycPdfModalConfirmationEventClicked = domain.event();

/**
 * Analytics event - KYC - Download PDF - Modal Cancel clicked
 */
export const downloadKycPdfModalCancelEventClicked = domain.event();

/**
 * Analytics event - Request Docs Button clicked
 */
export const requestDocsButtonClicked = domain.event();

/**
 * Analytics event - Negative Equity - Mark seller funds received button clicked */
export const markSellerFundsReceivedButtonClicked = domain.event();

/**
 * Analytics event - Negative Equity - Unmark seller funds received button clicked
 */
export const unmarkSellerFundsReceivedButtonClicked = domain.event();

/**
 * Analytics event - Negative Equity - Mark seller funds received modal opened */
export const markSellerFundsReceivedModalOpen = domain.event();

/**
 * Analytics event - Negative Equity - Mark seller funds received modal closed */
export const markSellerFundsReceivedModalClose = domain.event();

/**
 * Analytics event - Negative Equity - Mark seller funds received confirm button clicked */
export const markSellerFundsReceivedConfirmButtonClicked = domain.event();

/**
 * Analytics event - Negative Equity - Mark seller funds received cancel button clicked */
export const markSellerFundsReceivedCancelButtonClicked = domain.event();

/**
 * Analytics event - Negative Equity - Unmark seller funds received modal opened */
export const unmarkSellerFundsReceivedModalOpen = domain.event();

/**
 * Analytics event - Negative Equity - Unmark seller funds received modal closed */
export const unmarkSellerFundsReceivedModalClose = domain.event();

/**
 * Analytics event - Negative Equity - Unmark seller funds received confirm button clicked */
export const unmarkSellerFundsReceivedConfirmButtonClicked = domain.event();

/**
 * Analytics event - Negative Equity - Unmark seller funds received cancel button clicked */
export const unmarkSellerFundsReceivedCancelButtonClicked = domain.event();

/**
 * Analytics event - Negative Equity - Update finance settlement amount modal opened */
export const updateFinanceSettlementAmountModalOpen = domain.event();

/**
 * Analytics event - Negative Equity - Update finance settlement amount modal closed */
export const updateFinanceSettlementAmountModalClosed = domain.event();

/**
 * Analytics event - Negative Equity - Update finance settlement amount modal confirm clicked */
export const updateFinanceSettlementAmountModalConfirmClicked = domain.event();

/**
 * Analytics event - Negative Equity -Update finance settlement amount modal cancel clicked */
export const updateFinanceSettlementAmountModalCancelClicked = domain.event();

import type { Store } from 'effector';
import { combine } from 'effector';

import type { JSENDAPIListResponse } from '@core/http';
import { RELIST_DETAILS_MODAL_NAME } from '@pages/Vehicle/sections/relistDetails/RelistDetails.constant';

import * as users from '../agents/internal';
import * as app from '../app/internal';

import * as events from './events';
import type { RelistDetail, RelistDetailsWithUserNames, RelistReason, RequestNewRelistReason } from './model';
import { domain } from './utils';

/**
 * Relist reasons
 */
export const $relistReasons = domain
	.store<JSENDAPIListResponse<RelistReason[]>>({
		data: [
			{ defaultInfo: 'MOCK - default info', name: 'MOCK - name', slug: 'MOCK_SLUG' },
			{ defaultInfo: 'MOCK - default info 2', name: 'MOCK - name 2', slug: 'MOCK_SLUG2' },
		],
	})
	// .on(effects.getRelistReasons.doneData, (_, data) => data)
	.reset(app.reset);

/**
 * Relist reasons options modal autocomplete field
 */
export const $relistReasonsOptions = $relistReasons.map((relistReasons) =>
	relistReasons.data.map((relistReason) => ({
		label: relistReason.name,
		value: relistReason.slug,
	})),
);

/**
 * Relist reasons modal form
 */
export const $relistReasonForm = domain
	.store<RequestNewRelistReason>({})
	.on(events.setRelistReason, (state, payload) => ({ ...state, ...payload }))
	.on(events.setAdditionalInformation, (state, info) => ({ ...state, info }))
	.on(
		app.closeModal.map((modalName) => modalName === RELIST_DETAILS_MODAL_NAME),
		(state, isClosed) => (isClosed ? {} : state),
	);

/**
 * Relist details
 */
const $relistDetailsWithoutUserNames = domain
	.store<RelistDetail[]>([
		{
			createdAt: '2022-10-14T09:26:06.132Z',
			createdBy: 2176,
			id: 0,
			relistReason: { info: 'MOCK - not default info', name: 'MOCK - name', slug: 'MOCK_SLUG' },
			updatedAt: '2022-10-14T10:26:06.132Z',
			updatedBy: 2201,
		},
		{
			createdAt: '2022-10-14T09:26:06.132Z',
			createdBy: 2201,
			id: 1,
			relistReason: { info: 'MOCK - not default info 2', name: 'MOCK - name 2', slug: 'MOCK_SLUG2' },
			updatedAt: '2022-10-14T10:26:06.132Z',
			updatedBy: 2176,
		},
	])
	// .on(events.getRelistDetails.doneData, (_, data) => data.relistDetails);
	.reset(app.reset);

export const $relistDetails: Store<RelistDetailsWithUserNames[]> = combine(
	users.$allAgentsMap,
	$relistDetailsWithoutUserNames,
	(agents, relistDetails) =>
		relistDetails.map((relistDetail) => ({
			...relistDetail,
			createdByUser: agents[relistDetail.createdBy] || 'Unknown',
			updatedByUser: agents[relistDetail.updatedBy] || 'Unknown',
		})),
);
